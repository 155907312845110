/* bootstrap overwrite */
/* bootstrap overwrite */
.slider-actions slick-carousel-counter {
  --current-slide-color: #001f47;
  --separator-color: #546983;
  --slide-count-color: #546983;
}

.bg-dark .slider-actions slick-carousel-counter {
  --current-slide-color: #fff;
  --separator-color: #e4eaf7;
  --slide-count-color: #e4eaf7;
}

.teaser-component .teaser .teaser-body .teaser-decoration,
.detail-page-teaser-component .teaser .teaser-body .teaser-decoration,
.pages-overview-component .teaser .teaser-body .teaser-decoration {
  color: #657785;
}
.teaser-component .teaser .teaser-body .teaser-decoration .teaser-icon,
.detail-page-teaser-component .teaser .teaser-body .teaser-decoration .teaser-icon,
.pages-overview-component .teaser .teaser-body .teaser-decoration .teaser-icon {
  background: #fff;
}
.teaser-component .teaser .teaser-body .teaser-decoration .teaser-icon .icon-mask,
.detail-page-teaser-component .teaser .teaser-body .teaser-decoration .teaser-icon .icon-mask,
.pages-overview-component .teaser .teaser-body .teaser-decoration .teaser-icon .icon-mask {
  background: #657785;
}
.teaser-component .teaser:hover .teaser-decoration .teaser-icon .icon-mask,
.detail-page-teaser-component .teaser:hover .teaser-decoration .teaser-icon .icon-mask,
.pages-overview-component .teaser:hover .teaser-decoration .teaser-icon .icon-mask {
  background: var(--accent-hover-color);
}
.teaser-component .teaser:hover .teaser-tagline,
.detail-page-teaser-component .teaser:hover .teaser-tagline,
.pages-overview-component .teaser:hover .teaser-tagline {
  color: var(--accent-hover-color);
}
.teaser-component .teaser:hover .link,
.detail-page-teaser-component .teaser:hover .link,
.pages-overview-component .teaser:hover .link {
  color: var(--accent-hover-color);
}
.teaser-component .teaser-slider.highlight-slider .slick-slide.slick-current .teaser-body,
.detail-page-teaser-component .teaser-slider.highlight-slider .slick-slide.slick-current .teaser-body,
.pages-overview-component .teaser-slider.highlight-slider .slick-slide.slick-current .teaser-body {
  border-image-source: var(--accent-gradient);
}
.teaser-component .teaser-slider.highlight-slider .slick-slide.slick-current .teaser-body .teaser-decoration .teaser-icon::before,
.detail-page-teaser-component .teaser-slider.highlight-slider .slick-slide.slick-current .teaser-body .teaser-decoration .teaser-icon::before,
.pages-overview-component .teaser-slider.highlight-slider .slick-slide.slick-current .teaser-body .teaser-decoration .teaser-icon::before {
  background: var(--accent-gradient);
}
.teaser-component .teaser-slider.highlight-slider .slick-slide.slick-current .teaser-body .teaser-decoration .teaser-icon::after,
.detail-page-teaser-component .teaser-slider.highlight-slider .slick-slide.slick-current .teaser-body .teaser-decoration .teaser-icon::after,
.pages-overview-component .teaser-slider.highlight-slider .slick-slide.slick-current .teaser-body .teaser-decoration .teaser-icon::after {
  background: #fff;
}
.bg-light .teaser-component .teaser-slider .teaser .teaser-body, .bg-dark .teaser-component .teaser-slider .teaser .teaser-body,
.bg-light .teaser-component .grid .teaser .teaser-body,
.bg-dark .teaser-component .grid .teaser .teaser-body,
.bg-light .detail-page-teaser-component .teaser-slider .teaser .teaser-body,
.bg-dark .detail-page-teaser-component .teaser-slider .teaser .teaser-body,
.bg-light .detail-page-teaser-component .grid .teaser .teaser-body,
.bg-dark .detail-page-teaser-component .grid .teaser .teaser-body,
.bg-light .pages-overview-component .teaser-slider .teaser .teaser-body,
.bg-dark .pages-overview-component .teaser-slider .teaser .teaser-body,
.bg-light .pages-overview-component .grid .teaser .teaser-body,
.bg-dark .pages-overview-component .grid .teaser .teaser-body {
  padding: 0 16px 16px;
}
.teaser-component .teaser-slider .teaser .teaser-body .teaser-decoration .teaser-icon,
.teaser-component .grid .teaser .teaser-body .teaser-decoration .teaser-icon,
.detail-page-teaser-component .teaser-slider .teaser .teaser-body .teaser-decoration .teaser-icon,
.detail-page-teaser-component .grid .teaser .teaser-body .teaser-decoration .teaser-icon,
.pages-overview-component .teaser-slider .teaser .teaser-body .teaser-decoration .teaser-icon,
.pages-overview-component .grid .teaser .teaser-body .teaser-decoration .teaser-icon {
  display: none;
}
.teaser-component .teaser-slider .teaser .teaser-body .teaser-decoration .teaser-tagline,
.teaser-component .grid .teaser .teaser-body .teaser-decoration .teaser-tagline,
.detail-page-teaser-component .teaser-slider .teaser .teaser-body .teaser-decoration .teaser-tagline,
.detail-page-teaser-component .grid .teaser .teaser-body .teaser-decoration .teaser-tagline,
.pages-overview-component .teaser-slider .teaser .teaser-body .teaser-decoration .teaser-tagline,
.pages-overview-component .grid .teaser .teaser-body .teaser-decoration .teaser-tagline {
  margin-top: 8px;
}
.teaser-component .teaser-slider .teaser.teaser-highlight .teaser-body,
.teaser-component .grid .teaser.teaser-highlight .teaser-body,
.detail-page-teaser-component .teaser-slider .teaser.teaser-highlight .teaser-body,
.detail-page-teaser-component .grid .teaser.teaser-highlight .teaser-body,
.pages-overview-component .teaser-slider .teaser.teaser-highlight .teaser-body,
.pages-overview-component .grid .teaser.teaser-highlight .teaser-body {
  padding: 8px 16px 16px;
}
.teaser-component .teaser-slider .teaser.teaser-ambassador .teaser-image .image-mask,
.teaser-component .grid .teaser.teaser-ambassador .teaser-image .image-mask,
.detail-page-teaser-component .teaser-slider .teaser.teaser-ambassador .teaser-image .image-mask,
.detail-page-teaser-component .grid .teaser.teaser-ambassador .teaser-image .image-mask,
.pages-overview-component .teaser-slider .teaser.teaser-ambassador .teaser-image .image-mask,
.pages-overview-component .grid .teaser.teaser-ambassador .teaser-image .image-mask {
  mask: none;
}
.teaser-component .page-count,
.detail-page-teaser-component .page-count,
.pages-overview-component .page-count {
  color: #657785;
}
.bg-dark .teaser-component .page-count,
.bg-dark .detail-page-teaser-component .page-count,
.bg-dark .pages-overview-component .page-count {
  color: #fff;
}