@import 'webresources-sources/scss/imports';
@import '../../../includes/components/slick-carousel-counter';

.teaser-component,
.detail-page-teaser-component,
.pages-overview-component {
  .teaser {
    .teaser-body {
      .teaser-decoration {
        color: $color-gray-600;

        .teaser-icon {
          background: $color-white;

          .icon-mask {
            background: $color-gray-600;
          }
        }
      }
    }

    &:hover {
      .teaser-decoration {
        .teaser-icon {
          .icon-mask {
            background: var(--accent-hover-color);
          }
        }
      }

      .teaser-tagline {
        color: var(--accent-hover-color);
      }

      .link {
        color: var(--accent-hover-color);
      }
    }
  }

  .teaser-slider {
    &.highlight-slider {
      .slick-slide {
        &.slick-current {
          .teaser-body {
            border-image-source: var(--accent-gradient);

            .teaser-decoration {
              .teaser-icon {
                &::before {
                  background: var(--accent-gradient);
                }

                &::after {
                  background: $color-white;
                }
              }
            }
          }
        }
      }
    }
  }

  .teaser-slider,
  .grid {
    .teaser {
      .teaser-body {
        .bg-light &,
        .bg-dark & {
          padding: 0 16px 16px;
        }

        .teaser-decoration {
          .teaser-icon {
            display: none;
          }

          .teaser-tagline {
            margin-top: 8px;
          }
        }
      }

      &.teaser-highlight {
        .teaser-body {
          padding: 8px 16px 16px;
        }
      }

      &.teaser-ambassador {
        .teaser-image {
          .image-mask {
            mask: none;
          }
        }
      }
    }
  }

  .page-count {
    color: $color-gray-600;

    .bg-dark & {
      color: $color-white;
    }
  }
}
